import React from 'react';
import { RegionProvider } from './src/context/RegionContext';
require('./node_modules/bootstrap/dist/css/bootstrap.min.css');
require('./src/styles/global.scss');

export const wrapRootElement = ({ element }) => <RegionProvider>{element}</RegionProvider>;

export const onRouteUpdate = ({ location }) => {
  const prevRegion = window.localStorage.getItem('region');
  const region = window.___region || prevRegion || 'UK';

  // const cookies = document.cookie.split(';').map((cookie) => cookie.trim());
  // const hasAcceptedCookies = cookies.some((cookie) => cookie.startsWith('acceptCookies='));

  if (region) {
    console.log(`Set ${region} for ${location.pathname}`);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('event', 'region_event', {
      region: region,
    });
  }
};
